import { Component, OnInit } from '@angular/core';
import {
  ExperimentalClassContact,
  ExperimentalClassPostSchedule,
} from 'src/app/model';
import { FormBuilder, Validators } from '@angular/forms';
import { ExperimentalClassService } from 'src/app/services/experimentalClassService';
import { EnumTypeResponse } from 'src/app/enum/EnumTypeResponse';
import { formatDate } from '@angular/common';

@Component({
  selector: 'experimental-class',
  templateUrl: './experimental-class.component.html',
})
export class ExperimentalClassComponent implements OnInit {
  public msgReturnSuccess: boolean = false;
  public phoneMask: Array<string | RegExp>;
  dataReturn: any;
  returnUrl: string;

  public returnMessage = '';

  public validContact: boolean;
  public contactDataForm: any;
  public contactSubmitted = false;
  public returnMessageContact = '';
  private eClassContact: ExperimentalClassContact;

  public validDatetimePrefer: boolean;
  public datetimePreferForm: any;
  public datetimePreferSubmitted = false;
  private timePreferArray: Array<any> = [];
  private dayPreferArray: Array<any> = [];
  public experimentalClassArray: Array<any> = [];
  public returnMessageDatetimePrefer = '';

  public validPostSchedule: boolean;
  public postScheduleForm: any;
  public postScheduleSubmitted = false;
  public returnMessagePostSchedule = '';
  public eClassPostSchedule: ExperimentalClassPostSchedule;

  public currentStep: number = 1;

  constructor(
    private formBuilder: FormBuilder,
    private experimentalClassService: ExperimentalClassService
  ) {
    this.phoneMask = [
      '(',
      /[1-9]/,
      /[1-9]/,
      ')',
      ' ',
      /\d?/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
    this.onInitForm();
  }

  ngOnInit(): void {
    // Initialize the register form
    this.onInitForm();
    this.returnUrl = '/';
  }

  public isSmallScreen(): boolean {
    return window.screen.width < 1280;
  }

  onInitForm() {
    this.validContact = true;
    this.contactDataForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
        ],
      ],
      target: [0, [Validators.required, Validators.min(1)]],
      level: [0, [Validators.required, Validators.min(1)]],
      message: [''],
    });

    this.validDatetimePrefer = true;

    this.validPostSchedule = true;
    this.postScheduleForm = this.formBuilder.group({
      // DON'T FORGET THE FORM INITIALISATION
    });

    this.onSetEventsForm();
  }

  onSetEventsForm() {
    this.f_contact?.phone.valueChanges.subscribe((value: any) => {
      var strPhone = value
        .toString()
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace('_', '')
        .replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f_contact.phone.status = 'INVALID';
      }
    });
  }

  checkPhoneMask() {
    let value = this.f_contact?.phone?.value;
    var strPhone = value
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace('_', '')
      .replace(' ', '');
    var phoneNumber = parseInt(strPhone);
    if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
      value = phoneNumber.toString();
      this.f_contact.phone.status = 'INVALID';
      if (this.f_contact?.phone.errors == null) {
        this.f_contact.phone.errors = { invalid: true };
      } else {
        this.f_contact.phone.errors.invalid = true;
      }
    }
  }

  setPhone(input: any) {
    if (input?.currentTarget?.value) {
      this.setValueForm('phone', input?.currentTarget?.value);
    }
  }

  private setValueForm(control: any, value: any) {
    this.contactDataForm.controls[control].setValue(value);
  }

  private getValueForm(control: any): any {
    this.f_contact[control].value;
  }

  setTimePrefer(input: any) {
    if (input) {
      let inputId = input?.target?.id;
      let itInput = document.getElementById(inputId);
      if (itInput) {
        if (itInput.classList.contains('btn-light')) {
          itInput.classList.remove('btn-light');
          itInput.classList.add('btn-primary');
          this.timePreferArray.push(inputId);
        } else {
          itInput.classList.remove('btn-primary');
          itInput.classList.add('btn-light');
          const index = this.timePreferArray.indexOf(inputId, 0);
          if (index > -1) {
            this.timePreferArray.splice(index, 1);
          }
        }
      }
    }
  }

  setDayPrefer(input: any) {
    if (input) {
      let inputId = input?.target?.id;
      let itInput = document.getElementById(inputId);
      if (itInput) {
        if (itInput.classList.contains('btn-light')) {
          itInput.classList.remove('btn-light');
          itInput.classList.add('btn-primary');
          this.dayPreferArray.push(inputId);
        } else {
          itInput.classList.remove('btn-primary');
          itInput.classList.add('btn-light');
          const index = this.dayPreferArray.indexOf(inputId, 0);
          if (index > -1) {
            this.dayPreferArray.splice(index, 1);
          }
        }
      }
    }
  }

  setExperimentalClass(index: number, element: any, inputId: string) {
    if (inputId) {
      let itInput = document.getElementById(inputId);
      if (itInput) {
        if (itInput.classList.contains('bg-checked')) {
          itInput.classList.remove('bg-checked');
          // const index = this.experimentalClassArray.indexOf(inputId, 0);
          // if (index > -1) {
          //   this.experimentalClassArray.splice(index, 1);
          // }
        } else {
          let divItems = document.getElementsByClassName('bg-checked');
          if (divItems?.length > 0) {
            for (let i = 0; i < divItems.length; i++) {
              const element = divItems[i];
              element.classList.remove('bg-checked');
              // const index = this.experimentalClassArray.indexOf(element.id, 0);
              // if (index > -1) {
              //   this.experimentalClassArray.splice(index, 1);
              // }
            }
          }
          itInput.classList.add('bg-checked');
          //this.experimentalClassArray.push(inputId);
          this.eClassPostSchedule = new ExperimentalClassPostSchedule({
            scheduleDateSet: element.availableDate,
            experimentalClassContact: this.eClassContact,
            //experimentalLevel: this.targ
          });
        }
      }
    }
  }

  get f_contact() {
    return this.contactDataForm?.controls;
  }

  onSubmitContact(nextStep: number) {
    this.contactSubmitted = true;
    this.validContact = true;
    this.returnMessageContact = '';

    this.datetimePreferSubmitted = true;
    this.validDatetimePrefer = true;
    this.returnMessageDatetimePrefer = '';

    this.postScheduleSubmitted = true;
    this.validPostSchedule = true;
    this.returnMessagePostSchedule = '';

    this.checkPhoneMask();

    // stop here if form is invalid
    if (this.contactDataForm.invalid) {
      this.contactSubmitted = false;
      this.validContact = false;
      this.returnMessageContact = 'Informe todos os campos para continuar.';
      return;
    }

    this.eClassContact = new ExperimentalClassContact(
      this.contactDataForm.value
    );

    this.experimentalClassService.fixAvailabilities().subscribe(
      (responseAfterSuccess) => {
        if (responseAfterSuccess.typeResponse == EnumTypeResponse.Success) {
          this.msgReturnSuccess = true;
          this.experimentalClassArray = responseAfterSuccess.data;
          this.returnMessageContact = '';
          this.contactSubmitted = false;
          this.currentStep = nextStep;
        } else {
          this.msgReturnSuccess = true;
          this.validContact = false;
          this.contactSubmitted = false;
          this.returnMessageContact = responseAfterSuccess.message;
        }
      },
      (responseAfterError: any) => {
        this.validContact = false;
        this.contactSubmitted = false;
        this.returnMessageContact =
          'Ocorreu um erro ao filtrar as agendas disponíveis.';
      }
    );
  }

  formatDateTime(element: any): string {
    return formatDate(element.availableDate, 'dd/MM/yyyy HH:mm', 'en-US');
  }

  getDayOfWeek(element: any): string {
    if (element?.availableDate) {
      const diasDaSemana = [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
      ];

      // Pega o índice do dia da semana (0-6) com base na data fornecida
      const diaSemanaIndex = new Date(element.availableDate).getDay();

      // Retorna o nome do dia correspondente
      return diasDaSemana[diaSemanaIndex];
    }
    return '';
  }

  onGetExperimentalClasses(nextStep: number) {
    this.datetimePreferSubmitted = true;
    this.validDatetimePrefer = true;
    this.returnMessageDatetimePrefer = '';

    // stop here if form is invalid
    if (this.dayPreferArray?.length == 0 || this.timePreferArray?.length == 0) {
      this.datetimePreferSubmitted = false;
      this.validDatetimePrefer = false;
      this.returnMessageDatetimePrefer =
        'Selecione pelo menos uma opção para continuar.';
      return;
    }

    this.experimentalClassService
      .availabilities(this.dayPreferArray, this.timePreferArray)
      .subscribe(
        (responseAfterSuccess) => {
          if (responseAfterSuccess.typeResponse == EnumTypeResponse.Success) {
            this.msgReturnSuccess = true;
            this.experimentalClassArray = responseAfterSuccess.data;
            this.returnMessageDatetimePrefer = '';
            this.datetimePreferSubmitted = false;
            this.currentStep = nextStep;
          } else {
            this.msgReturnSuccess = true;
            this.validDatetimePrefer = false;
            this.datetimePreferSubmitted = false;
            this.returnMessageDatetimePrefer = responseAfterSuccess.message;
          }
        },
        (responseAfterError) => {
          this.validDatetimePrefer = false;
          this.datetimePreferSubmitted = false;
          this.returnMessageDatetimePrefer =
            'Erro ao carregar os horários disponíveis.';
        }
      );
  }

  onSubmitDatetimePrefer(nextStep: number) {
    this.datetimePreferSubmitted = true;
    this.validDatetimePrefer = true;
    this.returnMessageDatetimePrefer = '';

    // stop here if form is invalid
    if (this.dayPreferArray?.length == 0 || this.timePreferArray?.length == 0) {
      this.datetimePreferSubmitted = false;
      this.validDatetimePrefer = false;
      this.returnMessageDatetimePrefer =
        'Selecione pelo menos uma opção para continuar.';
      return;
    }

    this.experimentalClassService
      .availabilities(this.dayPreferArray, this.timePreferArray)
      .subscribe(
        (responseAfterSuccess) => {
          if (responseAfterSuccess.typeResponse == EnumTypeResponse.Success) {
            this.msgReturnSuccess = true;
            this.experimentalClassArray = responseAfterSuccess.data;
            this.returnMessageDatetimePrefer = '';
            this.datetimePreferSubmitted = false;
            this.currentStep = nextStep;
          } else {
            this.msgReturnSuccess = true;
            this.validDatetimePrefer = false;
            this.datetimePreferSubmitted = false;
            this.returnMessageDatetimePrefer = responseAfterSuccess.message;
          }
        },
        (responseAfterError) => {
          this.validDatetimePrefer = false;
          this.datetimePreferSubmitted = false;
          this.returnMessageDatetimePrefer =
            'Erro ao carregar os horários disponíveis.';
        }
      );
  }

  backStep(step: number) {
    this.currentStep = step;
  }

  onSubmitSchedule(nextStep: number) {
    this.postScheduleSubmitted = true;
    this.validPostSchedule = true;
    this.returnMessagePostSchedule = '';

    if (
      !(
        this.eClassPostSchedule?.scheduleDateSet &&
        this.eClassPostSchedule?.experimentalClassContact
      )
    ) {
      this.postScheduleSubmitted = false;
      this.validPostSchedule = false;
      this.returnMessagePostSchedule =
        'Por favor, selecione uma data e horário válidos.';
      return;
    }

    this.experimentalClassService
      .postSchedule(this.eClassPostSchedule)
      .subscribe(
        (responseAfterSuccess) => {
          if (responseAfterSuccess.typeResponse == EnumTypeResponse.Success) {
            this.msgReturnSuccess = true;
            this.validPostSchedule = true;
            this.postScheduleSubmitted = false;
            this.returnMessagePostSchedule = '';
            this.currentStep = nextStep;
          } else {
            this.msgReturnSuccess = false;
            this.validPostSchedule = false;
            this.postScheduleSubmitted = false;
            this.returnMessagePostSchedule = responseAfterSuccess.message;
          }
        },
        (responseAfterError) => {
          this.validPostSchedule = false;
          this.postScheduleSubmitted = false;
          this.returnMessagePostSchedule =
            'Ocorreu um erro ao agendar a aula. Por favor, tente novamente mais tarde!';
        }
      );
  }
}
